import React from "react";
import BaseLayout from "../components/BaseLayout";
import { SC_THANK_YOU } from "../styles/SC_ThankYou";

function ThankYouPage({ pageContext: { fechedOfferList } }) {
  return (
    <>
      <BaseLayout destailPage={true}>
        <SC_THANK_YOU>
          <div>
            <h1>Candidature reçue!</h1>
            <p>Merci de votre intérêt pour un emploi chez STAS Inc</p>
            <span className="postulationCTA">
              <a href="/">Retour à la page d'accueil</a>
            </span>
          </div>
        </SC_THANK_YOU>
      </BaseLayout>
    </>
  );
}

export default ThankYouPage;
