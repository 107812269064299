import styled from "styled-components";

export const SC_THANK_YOU = styled.div`
  padding: 7em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    padding: 3em 8em;
    border-radius: 1em;
    border: 5px solid #00173a;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-weight: 700;
      padding: 0.3em;
    }
    h3 {
      width: 80%;
      padding: 0 1em;
    }
    .postulationCTA {
      position: relative;
      /* background-color: blue; */
      /* border: 2px solid orange; */
      padding: 0.5em 0.9em;
      display: inline-block;
      background: url(/SVG/background-button-header.svg) center center;
      background-size: contain;
      background-repeat: no-repeat;
      a {
        width: 100%;
        text-decoration: none;
        color: #00173a;
      }
    }
  }
`;
